import React, { useEffect, useState } from 'react';

import { PanelData } from '@grafana/data';
import { Button } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { useStyles } from '@/styles';
import { createCSV, downloadCSV } from '@/utils/utils.csv';

export const ExportCSV = ({ data }: { data: PanelData | undefined }) => {
  const styles = useStyles();
  const [CSVdata, setCSVdata] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (data?.series) {
      let csv = createCSV(data.series);
      setCSVdata(csv);
    }
  }, [data]);

  // Download CSV file
  const downloadLogVolume = () => {
    return downloadCSV(CSVdata, 'log-volume');
  };

  return (
    <Button
      data-testid={testIds.lve.exportCSVBtn}
      className={styles.timeseriesContainer}
      disabled={!CSVdata}
      variant="secondary"
      icon="file-download"
      onClick={downloadLogVolume}
    >
      Download (CSV)
    </Button>
  );
};
