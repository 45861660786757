import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Alert, Stack } from '@grafana/ui';

import { ExportCSV } from '@/components/LogVolume/ExportCSV';
import { LinkToExplore } from '@/components/LogVolume/LinkToExplore';
import { barChartPanelOptions, getBarChartFrame } from '@/components/LogVolume/utils';
import { CHART } from '@/constants';
import { useGetTopVolume, useGetVolume } from '@/hooks/logs';
import { useUserPermissions } from '@/hooks/user';
import { useStyles } from '@/styles';

import { Panel } from '../Panel';

type Props = {
  header: string;
  hook: typeof useGetVolume | typeof useGetTopVolume;
  overviewPage?: boolean;
};

export const BarChart = ({ overviewPage, header, hook }: Props) => {
  const styles = useStyles();
  const { canAccessExplore } = useUserPermissions();

  // Get log volume for the selected labels
  const { data, isInitialLoading, isRefetching, error } = hook();
  const isLoading = isInitialLoading || isRefetching;
  let volumeData = data?.data.result;

  if (error?.data) {
    return (
      <Alert title={error.data.error}>
        <Stack direction="column">
          <div>{error.data.message}</div>
        </Stack>
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.skeletonBg}>
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'95%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'80%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'80%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'75%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'70%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'70%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'65%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'60%'} height={20} />
        <Skeleton className={styles.skeletonContainer} inline count={1} width={'50%'} height={20} />
      </div>
    );
  }

  if (!overviewPage && !volumeData) {
    return <p>Select a datasource and at least one label to view estimated log volume</p>;
  }

  return volumeData && volumeData.length > 0 ? (
    <div>
      {header}
      <Panel
        data={getBarChartFrame(volumeData)}
        options={barChartPanelOptions}
        title={'Volume'}
        chart={CHART.BARCHART}
      />
      <div className={styles.gridGroup}>
        {canAccessExplore && !overviewPage && <LinkToExplore />}
        {!overviewPage && <ExportCSV data={getBarChartFrame(volumeData)} />}
      </div>
    </div>
  ) : (
    <div className={styles.noData}>No data</div>
  );
};
