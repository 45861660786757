import { format } from 'date-fns';

import { DataFrame } from '@grafana/data';

export const downloadCSV = (data: any, filename: string) => {
  const today = new Date();
  const furlormattedDate = format(today, 'yyyy-MM-dd');
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.download = `${filename}_${furlormattedDate}.csv`;
  link.href = url;
  link.click();
};

// Define the type for the accumulator
// type Accumulator = Array<{ key: string; value: string; volume: string }>;
type Accumulator = string[];

export const createCSV = (data: DataFrame[]): string | undefined => {
  try {
    if (data.length === 0 || data[0].fields.length === 0) {
      return undefined;
    }
    // Keep track of label value pairs for the csv header
    let totalFields = 0;
    const dataFormatted = data[0].fields.reduce((acc: Accumulator, item, i) => {
      if (i > 0) {
        const csvRows = [];
        const allNames = item.name.split(/,/);
        totalFields = allNames.length;
        // Dynamically add label and value fields
        allNames.forEach((name, i) => {
          const [key, value] = name.split(/=/);
          csvRows.push(key.trim(), value.trim());
        });
        // Add volume in bytes
        const volume = item.values[0];
        csvRows.push(volume);
        // Convert to CSV string
        const csvString = csvRows.join(',');
        acc.push(csvString);
      }
      return acc;
    }, []);

    // Header dynamic label and value fields
    let csvHeader = [];
    for (let i = 0; i < totalFields; i++) {
      csvHeader.push('"Label"', '"Value"');
    }
    csvHeader.push('"Volume in bytes"');
    const csvContent = [csvHeader, ...dataFormatted].join('\n');

    return csvContent;
  } catch (error) {
    console.error('Error processing CSV:', error);
    return;
  }
};
